import React from 'react';
import { Link } from 'react-router-dom';

const Resources = () => {
  const resources = {
    iep: [
      { 
        name: 'Wrightslaw', 
        url: 'https://www.wrightslaw.com/',
        description: 'Comprehensive resource for special education law, advocacy, and IEP information.' 
      },
      { 
        name: 'Understood.org', 
        url: 'https://www.understood.org/',
        description: 'User-friendly information and resources for parents and educators of children with learning and attention issues.' 
      },
      { 
        name: 'Center for Parent Information and Resources (CPIR)', 
        url: 'https://www.parentcenterhub.org/',
        description: 'Provides information and resources to help parents of children with disabilities participate more effectively in their education.' 
      },
      {
        name: 'National Center for Learning Disabilities (NCLD)',
        url: 'https://www.ncld.org/',
        description: 'Offers support and resources for individuals with learning disabilities and their families.'
      },
      { 
        name: 'IEP Fact Sheets',
        url: 'https://www2.ed.gov/parents/needs/speced/iep/index.html',
        description: 'Fact sheets from the U.S. Department of Education covering various aspects of IEPs.'
      },
      {
        name: 'KidsHealth: IEPs',
        url: 'https://kidshealth.org/en/parents/iep.html',
        description: 'Information about IEPs written for parents in an easy-to-understand format.'
      },
    ],
    transition: [
      {
        name: 'National Secondary Transition Technical Assistance Center (NSTTAC)',
        url: 'https://www.nsttac.org/',
        description: 'Provides national leadership and support to assist states in improving their capacity to produce positive post-school outcomes.'
      },
      {
        name: 'PACER Center: Transition Resources',
        url: 'https://www.pacer.org/transition/',
        description: 'Offers a wealth of information, tools, and resources for families and educators navigating the transition process.'
      },
      {
        name: 'Think College',
        url: 'https://thinkcollege.net/',
        description: 'Focuses on increasing inclusive higher education options for students with intellectual disability.'
      },
      {
        name: 'Got Transition',
        url: 'https://www.gottransition.org/',
        description: 'The National Center on Secondary Education and Transition (NCSET)'
      },
      {
        name: 'Transition Coalition',
        url: 'https://www.transitioncoalition.org/',
        description: 'A program of the Disability Rights Education & Defense Fund (DREDF)'
      },
      {
        name: 'Promising Practices Network',
        url: 'http://www.promisingpractices.net/',
        description: 'Transition resources from PACER Center.'
      },
      {
        name: 'Center for Transition and Career Innovation (CTCI)',
        url: 'https://ctc-innovation.org/',
        description: 'Innovative research and training related to transition.'
      }
    ],
    assistiveTechnology: [
      {
        name: 'LD Technology',
        url: 'https://www.ldtechnology.org/', 
        description: 'Provides resources and information on assistive technology for individuals with learning disabilities.'
      },
      {
        name: 'Tech Matrix',
        url: 'https://techmatrix.org/',
        description: 'Helps educators, parents, and others find technology that can support the learning of students with disabilities.'
      },
      {
        name: 'Closing The Gap',
        url: 'https://www.closingthegap.com/',
        description: 'Provides information and resources on assistive technology, access, and accommodations for individuals with disabilities.'
      },
      {
        name: 'Alliance for Technology Access (ATA)',
        url: 'https://www.atia.org/',
        description: 'Now part of ATIA (Assistive Technology Industry Association) - Promotes assistive technology.'
      },
      {
        name: 'ABLEDATA',
        url: 'https://abledata.acl.gov/',
        description: 'Database of assistive technology products and information.'
      },
      {
        name: 'QIAT Consortium',
        url: 'https://qiat.org/',
        description: 'Quality Indicators for Assistive Technology (QIAT) provides tools to improve assistive technology services.'
      },
    ],
    advocacy: [
      { 
        name: 'Council of Parent Attorneys and Advocates (COPAA)', 
        url: 'https://www.copaa.org/',
        description: 'National education advocacy organization for students with disabilities.'
      },
      {
        name: 'Disability Rights Education & Defense Fund (DREDF)',
        url: 'https://dredf.org/', 
        description: 'Works to advance the civil and human rights of people with disabilities through legal advocacy, training, education, and public policy and legislative development.'
      },
      {
        name: 'The Arc',
        url: 'https://thearc.org/',
        description: 'Advocates for the rights and full participation of people with intellectual and developmental disabilities.'
      },
      {
        name: 'Autistic Self Advocacy Network (ASAN)',
        url: 'https://autisticadvocacy.org/',
        description: 'Run by and for autistic people, promoting self-advocacy and neurodiversity.'
      },
      {
        name: 'National Disability Rights Network (NDRN)',
        url: 'https://www.ndrn.org/',
        description: 'Provides legal advocacy services to people with disabilities.'
      },
    ],
    governmentAndPolicy: [
      { 
        name: 'U.S. Department of Education: Office of Special Education Programs (OSEP)', 
        url: 'https://sites.ed.gov/idea/',
        description: 'Official website for IDEA (Individuals with Disabilities Education Act) information and resources.' 
      },
      {
        name: 'National Dissemination Center for Children with Disabilities (NICHCY)',
        url: 'https://nichcy.org/',
        description: 'Provides information on disabilities and disability-related issues, focusing on children and youth.'
      },
      {
        name: 'Rehabilitation Act of 1973 (Sections 504 and 508)',
        url: 'https://www.dol.gov/agencies/odep/about/laws-and-regulations/laws/rehabilitation-act-of-1973',
        description: 'Provides accommodations and access for students with disabilities in federally funded programs.'
      },
    ],
    mentalHealth: [
      {
        name: 'National Alliance on Mental Illness (NAMI)',
        url: 'https://www.nami.org/',
        description: 'Provides support, education, and advocacy for individuals and families affected by mental illness.'
      },
      {
        name: 'Mental Health America (MHA)',
        url: 'https://www.mhanational.org/',
        description: 'Offers information, resources, and support for mental health and well-being.'
      },
      {
        name: 'The Jed Foundation',
        url: 'https://www.jedfoundation.org/',
        description: 'Focuses on protecting emotional health and preventing suicide among teens and young adults.'
      },
      {
        name: 'National Institute of Mental Health (NIMH)',
        url: 'https://www.nimh.nih.gov/',
        description: 'Conducts research and provides information on mental disorders.'
      }
    ],
    independentLiving: [
      {
        name: 'Independent Living Institute',
        url: 'https://www.independentliving.org/',
        description: 'Promotes independent living and disability rights.'
      },
      {
        name: 'Association of Programs for Rural Independent Living (APRIL)',
        url: 'https://www.april-rural.org/',
        description: 'Works to maximize the independence and full participation of individuals with disabilities in rural communities.'
      },
      {
        name: 'Autistic Self Advocacy Network: Independent Living',
        url: 'https://autisticadvocacy.org/independent-living/',
        description: 'Autistic-led resources for independent living.'
      }
    ],
    employment: [
      {
        name: 'Office of Disability Employment Support (ODES)',
        url: 'https://www.dol.gov/agencies/odep',
        description: 'Part of the U.S. Department of Labor, provides resources and support for employment of people with disabilities.'
      },
      {
        name: 'Job Accommodation Network (JAN)',
        url: 'https://askjan.org/',
        description: 'Offers guidance on workplace accommodations and disability employment laws.'
      },
      {
        name: 'ThinkWork!',
        url: 'https://www.thinkwork.org/',
        description: 'Resources and support for youth with disabilities to successfully transition to employment.'
      }
    ],
    postSecondaryEducation: [
      {
        name: 'HEATH Resource Center',
        url: 'https://heath.gwu.edu/',
        description: 'Provides information and resources on postsecondary education for students with disabilities.'
      },
      {
        name: 'College Autism Network (CAN)',
        url: 'https://collegeautismnetwork.org/',
        description: 'A resource for students with autism, families, and higher education professionals.'
      },
      {
        name: 'Ahead (Association on Higher Education And Disability)',
        url: 'https://ahead.org/',
        description: 'Professional association dedicated to full participation of persons with disabilities in postsecondary education.'
      }
    ]
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md border border-black">
      <h1 className="text-3xl font-bold mb-6">Valuable Resources</h1>

      {Object.entries(resources).map(([category, links]) => (
        <section key={category} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            {category.replace(/([A-Z])/g, ' $1').toUpperCase()} Resources
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            {links.map((resource) => (
              <li key={resource.url}>
                <a
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {resource.name}
                </a>
                {resource.description && (
                  <p className="text-gray-600 mt-1 ml-4">{resource.description}</p>
                )}
              </li>
            ))}
          </ul>
        </section>
      ))}
    </div>
  );
};

export default Resources;