import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);

  return (
    <ChatContext.Provider value={{ chatMessages, setChatMessages, suggestedQuestions, setSuggestedQuestions }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
