import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';

const FileUpload = ({ onUploadComplete, onProgress, onError }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // Check file type
    const fileType = selectedFile.type;
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    const isValidType = fileType === 'application/pdf' || 
                       fileType === 'text/plain' ||
                       fileExtension === 'pdf' ||
                       fileExtension === 'txt';

    if (!isValidType) {
      onError("Please select a PDF or TXT file.");
      return;
    }

    // Check file size (10MB limit)
    if (selectedFile.size <= 10 * 1024 * 1024) {
      setFile(selectedFile);
    } else {
      onError("Please select a file under 10MB.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    setUploading(true);
    onProgress(0);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_BASE_URL}/api/upload-document`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        },
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.startsWith("data: ")) {
            const data = JSON.parse(line.slice(5));
            if (data.progress) {
              onProgress(data.progress);
            } else if (data.message) {
              onUploadComplete(data);
            } else if (data.error) {
              onError(data.error);
            }
          } else if (line === "event: close") {
            setUploading(false);
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      onError("Error uploading file. Please try again.");
      setUploading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <input
        type="file"
        onChange={handleFileChange}
        accept=".pdf,.txt"
        className="mb-2 block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-blue-50 file:text-blue-700
          hover:file:bg-blue-100"
      />
      <div className="text-xs text-gray-500 mb-2">
        Accepted files: PDF, TXT (max 10MB)
      </div>
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
        disabled={!file || uploading}
      >
        {uploading ? "Uploading..." : "Upload Document"}
      </button>
    </form>
  );
};

export default FileUpload;
