// Helper function for formatting content
const convertToQuillFormat = (content) => {
  const lines = content.split('\n');
  let formattedContent = '';
  
  lines.forEach(line => {
    if (line.startsWith('# ')) {
      formattedContent += `<h1 style="text-align: center;"><strong>${line.replace('# ', '')}</strong></h1>\n\n`;
    }
    else if (line.startsWith('## ')) {
      formattedContent += `<h2><strong>${line.replace('## ', '')}</strong></h2>\n\n`;
    }
    else if (line.startsWith('### ')) {
      formattedContent += `<h3><strong>${line.replace('### ', '')}</strong></h3>\n\n`;
    }
    else if (line.startsWith('• ') || line.startsWith('- ')) {
      if (line.includes(':**')) {
        const [label, value] = line.replace(/[•-]\s*\*\*/, '').split(':**');
        formattedContent += `<p>• <strong>${label}:</strong>${value || ''}</p>\n`;
      } else if (line.includes(':')) {
        const [label, value] = line.replace(/[•-]\s*/, '').split(':');
        formattedContent += `<p>• <strong>${label}:</strong>${value || ''}</p>\n`;
      } else {
        formattedContent += `<p>${line}</p>\n`;
      }
    }
    else {
      formattedContent += `<p>${line}</p>\n`;
    }
  });

  return formattedContent
    .replace(/\n{3,}/g, '\n\n')
    .replace(/\*\*/g, '')
    .trim();
};

// Template generator functions
export const generateIEP = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  const context = fromTemplate && chatMessages.length === 0
    ? "Generate a new standard IEP template"
    : chatMessages.slice(-5).map(msg => msg.content).join('\n');

  const response = await fetchWithToken(`${API_BASE_URL}/api/generate-iep-richtext`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      context,
      fromTemplate,
    }),
  });

  const data = await response.json();
  return convertToQuillFormat(data.iepTemplate);
};

export const generateUDL = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  const context = fromTemplate && chatMessages.length === 0
    ? "Generate a new standard UDL template"
    : chatMessages.slice(-5).map(msg => msg.content).join('\n');

  const response = await fetchWithToken(`${API_BASE_URL}/api/generate-udl`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      context,
      fromTemplate,
    }),
  });

  const data = await response.json();
  return convertToQuillFormat(data.udlTemplate);
};

export const generateLessonPlan = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  try {
    const response = await fetchWithToken(`${API_BASE_URL}/api/generate-lesson-plan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: chatMessages,
        fromTemplate,
      }),
    });

    const data = await response.json();
    
    if (!data || !data.content) {
      throw new Error('Invalid response format from server');
    }

    return convertToQuillFormat(data.content);
  } catch (error) {
    console.error('Error generating lesson plan:', error);
    throw error;
  }
}; 

export const generateDataCollection = async (fetchWithToken, API_BASE_URL, chatMessages, fromTemplate = false) => {
  try {
    const context = fromTemplate && chatMessages.length === 0
      ? "Generate a new standard data collection template"
      : chatMessages.slice(-5).map(msg => msg.content).join('\n');

    const response = await fetchWithToken(`${API_BASE_URL}/api/generate-data-collection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        context,
      }),
    });

    const data = await response.json();
    
    if (!data || !data.dataCollectionTemplate) {
      throw new Error('Invalid response format from server');
    }

    return convertToQuillFormat(data.dataCollectionTemplate);
  } catch (error) {
    console.error('Error generating data collection:', error);
    throw error;
  }
};