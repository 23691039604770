import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';

const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [about, setAbout] = useState('');
  const [isAboutLoaded, setIsAboutLoaded] = useState(false);
  const [hasUserTyped, setHasUserTyped] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [isFeedbackSaving, setIsFeedbackSaving] = useState(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);

  const placeholderText = "Tell us about yourself. This information helps the AI understand you better during conversations. For example: 'I'm a special education teacher with 8 years of experience, specializing in working with students who have learning disabilities. I'm passionate about inclusive education and differentiated instruction. I enjoy reading educational research and implementing new strategies in my classroom.'";

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${API_BASE_URL}/api/get-about`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.about) {
          setAbout(data.about);
          setHasUserTyped(true);
        }
        setIsAboutLoaded(true);
      } catch (error) {
        console.error('Error fetching about:', error);
        setIsAboutLoaded(true);
      }
    };

    if (isAuthenticated) {
      fetchAbout();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleSaveAbout = async () => {
    setIsSaving(true);
    setSaveSuccess(false);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_BASE_URL}/api/save-about`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ about })
      });
      const data = await response.json();
      console.log(data.message);
      setSaveSuccess(true);
    } catch (error) {
      console.error('Error saving about:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAboutChange = (e) => {
    setAbout(e.target.value);
    setHasUserTyped(true);
  };

  const handleSaveFeedback = async () => {
    setIsFeedbackSaving(true);
    setFeedbackSuccess(false);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_BASE_URL}/api/save-feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ feedback })
      });
      const data = await response.json();
      setFeedbackSuccess(true);
      setFeedback(''); // Clear feedback after successful submission
    } catch (error) {
      console.error('Error saving feedback:', error);
    } finally {
      setIsFeedbackSaving(false);
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  
  return (
    <div className="max-w-2xl mx-auto mt-10">
      <h2 className="text-3xl font-bold mb-6">Profile</h2>
      <div className="bg-white shadow-md rounded-lg p-6">
        <img src={user.picture} alt={user.name} className="w-24 h-24 rounded-full mx-auto mb-4" />
        <h3 className="text-2xl font-semibold text-center mb-2">{user.name}</h3>
        <p className="text-gray-600 text-center mb-6">{user.email}</p>
        <div>
          <h4 className="text-xl font-semibold mb-2">About</h4>
          <textarea 
            value={about} 
            onChange={handleAboutChange}
            className="w-full p-2 border border-gray-300 rounded-md mb-4"
            rows="4"
            placeholder={(!isAboutLoaded || (!hasUserTyped && about === '')) ? placeholderText : ''}
          />      
          <button 
            onClick={handleSaveAbout}
            className={`btn-secondary w-full mb-4 ${
              isSaving ? 'bg-gray-400 cursor-not-allowed' : ''
            }`}
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </button>
          {saveSuccess && (
            <p className="text-black mt-2 text-center">Changes saved successfully!</p>
          )}
        </div>
      </div>
      <div className="mt-6 flex flex-col items-center">
        <button 
          onClick={() => setShowFeedback(!showFeedback)}
          className="btn-secondary w-48 mb-4"
        >
          {showFeedback ? 'Hide Feedback Form' : 'Give Feedback'}
        </button>

        {showFeedback && (
          <div className="bg-white shadow-md rounded-lg p-6">
            <h4 className="text-xl font-semibold mb-2">Your Feedback</h4>
            <textarea 
              value={feedback} 
              onChange={(e) => setFeedback(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md mb-4"
              rows="4"
              placeholder="Please share your thoughts, suggestions, or report any issues..."
            />      
            <button 
              onClick={handleSaveFeedback}
              className={`btn-secondary w-full mb-4 ${
                isFeedbackSaving ? 'bg-gray-400 cursor-not-allowed' : ''
              }`}
              disabled={isFeedbackSaving}
            >
              {isFeedbackSaving ? 'Sending...' : 'Send Feedback'}
            </button>
            {feedbackSuccess && (
              <p className="text-green-600 mt-2 text-center">Thank you for your feedback!</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
