import React from "react";

const Terms = () => {
  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md border border-black">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <div className="prose prose-gray">
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>
        
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">1. Agreement to Terms</h2>
          <p>
            By accessing and using Asterio (the "Service"), you accept and agree to be bound by the terms and 
            provisions of this agreement. If you do not agree to abide by the above, please do not use this Service.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">2. Description of Service</h2>
          <p>
            Asterio is a web application designed to support educators, students, and families in navigating 
            special education and secondary transition. It provides AI-powered guidance, document management, 
            and other related services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">3. Privacy and Data Usage</h2>
          <p>
            Asterio is committed to protecting your privacy. We do not store personal data beyond what is 
            necessary for the functioning of the Service. Specifically:
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>All chat histories are automatically deleted after 30 days.</li>
            <li>We do not use personal data for any purpose other than providing and improving the Service.</li>
            <li>Users are responsible for ensuring that they do not upload or input sensitive personal information into the Service.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">4. User Responsibilities</h2>
          <p>
            Users are responsible for maintaining the confidentiality of their account and password. Users 
            agree to accept responsibility for all activities that occur under their account or password.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">5. Intellectual Property</h2>
          <p>
            The Service and its original content, features, and functionality are owned by Asterio and are 
            protected by international copyright, trademark, patent, trade secret, and other intellectual 
            property or proprietary rights laws.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">6. Limitation of Liability</h2>
          <p>
            Asterio shall not be liable for any indirect, incidental, special, consequential or punitive 
            damages, including without limitation, loss of profits, data, use, goodwill, or other intangible 
            losses, resulting from your access to or use of or inability to access or use the Service.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">7. Disclaimer</h2>
          <p>
            The Service is provided on an "AS IS" and "AS AVAILABLE" basis. Asterio does not warrant that 
            the Service will be uninterrupted, timely, secure, or error-free. The information provided by 
            the Service is for general informational purposes only and should not be considered as professional advice.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">8. Changes to Terms</h2>
          <p>
            We reserve the right to modify these Terms at any time. We will always post the most current 
            version on our website. By continuing to use the Service after changes become effective, you 
            agree to be bound by the revised terms.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">9. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the laws of [Your State/Country], 
            without regard to its conflict of law provisions.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-3">10. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at [Your Contact Email].
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
