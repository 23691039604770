import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { confirmAlert } from 'react-confirm-alert'; // You'll need to install this package
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the styles

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';


const History = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [chatHistory, setChatHistory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [collapsedDates, setCollapsedDates] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHistory, setFilteredHistory] = useState({});

  const fetchWithToken = useCallback(
    async (url, options = {}) => {
      const token = await getAccessTokenSilently();
      return fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    [getAccessTokenSilently],
  );

  const schema = {
    ...defaultSchema,
    tagNames: [...defaultSchema.tagNames, 'mark'],
    attributes: {
      ...defaultSchema.attributes,
      mark: [], // Allow no attributes on mark
    },
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  function highlightText(text, term) {
    if (!term) return text;
    const regex = new RegExp(`(${escapeRegExp(term)})`, 'gi');
    return text.replace(regex, '<mark>$1</mark>');
  }

  const fetchChatHistory = useCallback(async () => {
    try {
      const response = await fetchWithToken(
        `${API_BASE_URL}/api/chat-history`,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch chat history");
      }
      const history = await response.json();

      const organizedHistory = history.reduce((acc, message) => {
        const date = new Date(
          message.timestamp.replace(" ", "T"),
        ).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {});

      setChatHistory(organizedHistory);
      // Initialize all dates as expanded
      setCollapsedDates(
        Object.keys(organizedHistory).reduce((acc, date) => {
          acc[date] = true;
          return acc;
        }, {}),
      );
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchWithToken]);

  useEffect(() => {
    fetchChatHistory();
  }, [fetchChatHistory]);

  useEffect(() => {
    const filtered = Object.entries(chatHistory).reduce((acc, [date, messages]) => {
      const filteredMessages = messages.filter(message => 
        message.message.toLowerCase().includes(searchTerm.toLowerCase()) ||
        date.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredMessages.length > 0) {
        acc[date] = filteredMessages;
      }
      return acc;
    }, {});
    setFilteredHistory(filtered);
  }, [searchTerm, chatHistory]);

  const handleExport = async (format) => {
    try {
      let exportData;
      let mimeType;
      let fileExtension;

      switch (format) {
        case "txt":
          exportData = Object.entries(chatHistory)
            .map(([date, messages]) => {
              return `Date: ${date}\n${messages.map((msg) => `${msg.sender}: ${msg.message}`).join("\n")}`;
            })
            .join("\n\n");
          mimeType = "text/plain";
          fileExtension = "txt";
          break;
        case "csv":
          exportData = "Date,Sender,Message\n";
          Object.entries(chatHistory).forEach(([date, messages]) => {
            messages.forEach((msg) => {
              exportData += `"${date}","${msg.sender}","${msg.message.replace(/"/g, '""')}"\n`;
            });
          });
          mimeType = "text/csv";
          fileExtension = "csv";
          break;
        case "json":
          const sanitizedHistory = Object.entries(chatHistory).reduce(
            (acc, [date, messages]) => {
              acc[date] = messages.map((msg) => ({
                sender: msg.sender,
                timestamp: msg.timestamp,
                message: msg.message,
              }));
              return acc;
            },
            {},
          );
          exportData = JSON.stringify(sanitizedHistory, null, 2);
          mimeType = "application/json";
          fileExtension = "json";
          break;
        case "md":
          exportData = Object.entries(chatHistory)
            .map(([date, messages]) => {
              return `# ${date}\n\n${messages.map((msg) => `**${msg.sender === "user" ? "You" : "Assistant"}:** ${msg.message}`).join("\n\n")}`;
            })
            .join("\n\n---\n\n");
          mimeType = "text/markdown";
          fileExtension = "md";
          break;
        default:
          throw new Error("Unsupported export format");
      }

      const blob = new Blob([exportData], { type: mimeType });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `chat_history.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting chat history:", error);
    }
  };

  const toggleDateCollapse = (date) => {
    setCollapsedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const handleDeleteAllHistory = async () => {
    confirmAlert({
      title: 'Confirm deletion',
      message: 'Are you sure you want to delete all chat history? This action cannot be undone.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const response = await fetchWithToken(
                `${API_BASE_URL}/api/chat-history`,
                {
                  method: 'DELETE',
                }
              );
              if (!response.ok) {
                throw new Error("Failed to delete chat history");
              }
              setChatHistory({});
              setFilteredHistory({});
              setSearchTerm("");
            } catch (error) {
              console.error("Error deleting chat history:", error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  if (isLoading) {
    return <div className="text-center">Loading chat history...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Chat History</h1>
      <div className="mb-4 space-x-2">
        <button
          onClick={() => handleExport("json")}
          className="btn-secondary"
        >
          Export as JSON
        </button>
        <button
          onClick={() => handleExport("md")}
          className="btn-secondary"
        >
          Export as Markdown
        </button>
        <button
          onClick={handleDeleteAllHistory}
          className="btn-secondary"
        >
          Delete All History
        </button>
      </div>
      {/* Add search input */}
      <input
        type="text"
        placeholder="Search chat history. Found content will highlight in yellow."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      />
      {Object.keys(filteredHistory).length === 0 ? (
        <p>No history matching the search term.</p>
      ) : (
        Object.entries(filteredHistory).map(([date, messages]) => (
          <div key={date} className="mb-4 border rounded-lg overflow-hidden">
            <button
              onClick={() => toggleDateCollapse(date)}
              className="w-full text-left p-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <h2 className="text-xl font-semibold">
                {date}
                <span className="ml-2 text-sm text-gray-500">
                  {collapsedDates[date] ? "▼" : "▲"}
                </span>
              </h2>
            </button>
            {!collapsedDates[date] && (
              <div className="p-4 space-y-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg ${
                      message.sender === "user"
                        ? "bg-blue-100"
                        : "bg-white border border-gray-200"
                    }`}
                  >
                    <div className="flex justify-between items-center mb-2">
                      <strong>
                        {message.sender === "user" ? "You" : "Assistant"}:
                      </strong>
                      <span className="text-sm text-gray-500">
                        {new Date(message.timestamp).toLocaleString('en-US', { 
                          hour: 'numeric', 
                          minute: 'numeric',
                          hour12: true,
                          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        })}
                      </span>
                    </div>
                    <ReactMarkdown
                      className="prose max-w-none"
                      children={highlightText(message.message, searchTerm)}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[
                        [rehypeRaw],
                        [rehypeSanitize, schema],
                      ]}
                    />
                  </div>
                )).reverse()}
              </div>
            )}
          </div>
        ))
      )}
    </div>
   );
  };

export default History;
