import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import React, { useEffect } from "react";

const Layout = ({ children }) => {
  const { logout, user, error } = useAuth0();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (error) {
      console.error("Auth0 error:", error);
    }
  }, [error]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 pt-8 pb-2">
      <div className="max-w-7xl mx-auto h-[calc(100vh-2rem)] bg-white rounded-lg shadow-[0_2px_10px] shadow-black-200/70 border border-black-200/60 overflow-hidden flex">
        <Sidebar />
        <div className="flex-1 flex flex-col min-w-0">
          <header className="bg-white shadow-sm p-4 flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-800">
            
            </h1>
            <div className="flex items-center">
              <span className="mr-4 text-gray-600">Welcome, {user.name}</span>
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="btn-secondary"
              >
                Log Out
              </button>
            </div>
          </header>
          <main className="flex-1 overflow-y-auto p-2">
            <div className="h-full">{children}</div>
          </main>
        </div>
      </div>
      <footer className="bg-gray-100 py-0 px-4 text-center text-sm text-gray-600">
        © {currentYear} 21GlassesLLC. All rights reserved.{" "}
        <span className="mx-2">|</span>
        <Link
          to="/terms"
          className="inline py-1 px-4 text-gray-700 hover:bg-gray-100 hover:border hover:border-black transition-all duration-200 active:translate-y-[1px] active:shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500"
        >
          Terms & Conditions
        </Link>
      </footer>
    </div>
  );
};

export default Layout;
