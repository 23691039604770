import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

const Welcome = () => {
  const { loginWithRedirect } = useAuth0();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const screenshots = [
    '/screenshots/screenshot1a.png',
    '/screenshots/screenshot2.png',
    '/screenshots/screenshot3.png',
    '/screenshots/screenshot4.png',
    '/screenshots/screenshot5.png',
    '/screenshots/screenshot6.png',
    '/screenshots/screenshot7.png',
    // Add more screenshots as needed
  ];

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === screenshots.length - 1 ? 0 : prev + 1
    );
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? screenshots.length - 1 : prev - 1
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-white via-white" style={{ '--tw-gradient-stops': 'var(--tw-gradient-from) 30%, var(--tw-gradient-to) 70%' }}>
      <div className="p-8 max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-4 text-blue-900 text-center">
          Welcome to Asterio!
        </h1>

        <p className="mb-4 text-gray-700">
          We're thrilled to have you join our community of educators, families, and support staff dedicated to creating inclusive learning environments.
        </p>

        <div className="bg-gray-50 border border-black rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-bold mb-4 text-blue-900 text-center">Our Mission</h2>
          <p className="text-gray-700">
            At Asterio, we envision a future where education is personalized, accessible, and empowering for all. Our mission is to:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-700">
            <li>Bridge the information gap in special education, providing resources on IEPs, transition goals, and holistic student development.</li>
            <li>Introduce educators and families to the transformative potential of AI and Large Language Models (LLMs) in education.</li>
            <li>Prepare our community for the evolving educational landscape, where personalized learning and ubiquitous knowledge access become the norm.</li>
            <li>Support the transition of educators into roles as learning facilitators, mentors, and personalized learning architects.</li>
            <li>Foster collaboration between AI tools and human expertise to create more effective, engaging, and equitable learning experiences.</li>
          </ul>
          <p className="mt-4 text-gray-700">
            We believe in empowering educators and families with innovative tools and knowledge, ensuring that every learner receives tailored support for their unique journey. Together, we're building a future where education adapts to the individual, not the other way around.
          </p>
        </div>

        <p className="mb-8 text-gray-700">
          Asterio is your AI-powered chat companion for special education, here to help you brainstorm, plan, and support every student's unique journey in this exciting new era of learning.
        </p>

        <div className="flex justify-center mb-16">
          <button
            id="start-exploring"
            onClick={() => loginWithRedirect()}
            className="px-6 py-3 text-xl font-semibold bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-offset-2"
          >
            Start Exploring
          </button>
        </div>

        <div className="bg-gray-50 border border-black rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-bold mb-4 text-blue-900 text-center">About Asterio</h2>
          
          <h3 className="text-xl font-semibold mb-2">Our Story</h3>
          <p className="text-gray-700 mb-6">
            Asterio is more than just an app – it's a passion project born from the intersection of education, technology, and a deep desire to make a difference. Founded by a husband-and-wife team, Asterio brings together decades of experience in special education and technology to create a tool that truly understands and addresses the needs of educators, students, and families navigating the world of special education.
          </p>

          <h3 className="text-xl font-semibold mb-2">Meet the Team</h3>
          
          <div className="mb-4">
            <h4 className="text-lg font-semibold">Dr. Jennifer Burnes</h4>
            <p className="text-sm mb-2 text-blue-600">Co-founder and Education Expert</p>
            <p className="text-gray-700 mb-4">
              With a unique combination of a Ph.D. in Educational Psychology (Special Education) and a Master's in Statistics, Jennifer brings both analytical acumen and deep educational expertise to Asterio. Her 20+ years in education span hands-on work as a transition specialist for students with disabilities, as well as research experience as a doctoral student and educator.
            </p>
            <p className="text-gray-700 mb-4">
              At Asterio, Jennifer plays a crucial role in shaping the platform's educational content and ensuring its alignment with best practices in special education. Her background in teaching graduate-level special education courses allows her to understand educators' needs and shape Asterio's resources to be both practical and impactful. Jennifer's strong foundation in data analytics, research methodology, and program evaluation ensures that Asterio's tools are not only evidence-based but also focused on creating meaningful, real-world outcomes for students with disabilities.
            </p>
            <p className="text-gray-700 mb-2">Jennifer's expertise is instrumental in:</p>
            <ul className="list-disc pl-6 mb-6 text-gray-700">
              <li>Defining the educational principles that underpin Asterio's intelligent assistance features</li>
              <li>Curating and validating the knowledge base that informs our AI-powered responses</li>
              <li>Interpreting data analytics to continually improve the system's effectiveness</li>
              <li>Bridging the gap between advanced technology and educational best practices</li>
            </ul>
          </div>

          <div className="mb-6">
            <h4 className="text-lg font-semibold">Nathan Morton</h4>
            <p className="text-sm mb-2 text-blue-600">Co-founder and Technology Architect</p>
            <p className="text-gray-700 mb-4">
              A veteran of the early internet era, Nathan brings over two decades of experience in software development and system architecture to Asterio. His passion for technology and continuous learning drives Asterio's innovative approach to special education support.
            </p>
            <p className="text-gray-700 mb-2">As the technology lead, Nathan is responsible for:</p>
            <ul className="list-disc pl-6 mb-4 text-gray-700">
              <li>Architecting Asterio's robust and scalable technical infrastructure</li>
              <li>Overseeing the development of core platform features and user interfaces</li>
              <li>Integrating cutting-edge technologies to enhance Asterio's capabilities</li>
              <li>Ensuring the security, reliability, and performance of the platform</li>
            </ul>
            <p className="text-gray-700">
              Nathan's expertise spans a wide range of technologies and development practices. He leverages this knowledge to create a seamless, responsive experience for educators and students alike. His approach to system design focuses on creating intuitive, practical tools that address the unique challenges of special education.
            </p>
            <p className="text-gray-700 mt-4">
              Under Nathan's guidance, Asterio employs a flexible technology stack that can adapt to the evolving needs of educational technology. His vision for Asterio goes beyond just building a platform; he aims to create a technological ecosystem that empowers educators, supports students, and advances the field of special education through thoughtful application of proven technologies.
            </p>
          </div>
        </div>

        <div className="bg-gray-50 border border-black rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-bold mb-4 text-blue-900 text-center">Why Asterio?</h2>
          <ul className="list-disc pl-6 mb-6 text-gray-700">
            <li>Expert-Driven: Our app is built on a foundation of real-world experience and academic research in special education.</li>
            <li>Tech-Savvy: We leverage cutting-edge technology to create intuitive, powerful tools.</li>
            <li><span className="font-semibold">AI Assistant:</span> Get instant, intelligent responses to your special education questions</li>
            <li>Personal Touch: As a small, dedicated team, we pour our hearts into every feature and update.</li>
            <li><span className="font-semibold">IEP Tools:</span> Create comprehensive IEPs with our advanced template system</li>
            <li><span className="font-semibold">Document Chat:</span> Store and chat with your educational documents that you have questions about.</li>
            <li>Continuous Improvement: We're always learning, always improving, and always listening to our users.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2 text-center">Join Us on This Journey</h3>
          <p className="text-gray-700 mb-4">
            We're excited to have you with us as we work to revolutionize special education support. Whether you're an educator, a parent, or a student, we're here to support you every step of the way.
          </p>
          <p className="text-gray-700">
            Have questions or suggestions? We'd love to hear from you! <a href="mailto:asterio@asterio.io" className="text-blue-600 hover:text-blue-800">Contact Us</a>
          </p>
        </div>

        <div className="bg-gray-50 border border-black rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-3xl font-bold mb-4 text-blue-900 text-center">App Preview</h2>
          
          <div className="relative">
            <img 
              src={screenshots[currentImageIndex]} 
              alt={`App screenshot ${currentImageIndex + 1}`}
              className="w-full h-auto rounded-lg"
            />
            
            <button 
              onClick={previousImage}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
              aria-label="Previous image"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            
            <button 
              onClick={nextImage}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75"
              aria-label="Next image"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>

            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {screenshots.map((_, index) => (
                <div
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === currentImageIndex ? 'bg-blue-600' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-8">
          <button
            onClick={() => loginWithRedirect()}
            className="px-6 py-3 text-xl font-semibold bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-offset-2"
          >
            Start Exploring
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
